
import { Vue, Options } from "vue-class-component";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { formatPrice, whatsapp } from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";
import {
  CourierPartnerCodDashboard,
  ListCourierPartnerCodDashboard
} from "@/domain/entities/Dashboard";
import PopupSetorHarian from "@/app/ui/views/dashboard/cod-dashboard/component/popup-setor-harian-kurir.vue";

@Options({
  components: {
    PopupSetorHarian
  }
})
export default class ListCourierPartnerSection extends Vue {
  mounted() {
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.fetchCourierPartner();
  }
  // account type
  get dataProfile() {
    return AccountController.accountData;
  }
  get accountType() {
    return this.dataProfile.account_type;
  }
  get accountTypeDetail() {
    return this.dataProfile.account_type_detail.type;
  }

  get isPos() {
    return AccountController.accountData.isPosAccount;
  }

  get titleListCourierPartner() {
    if (this.accountTypeDetail === "pos") {
      return "List Courier";
    }
    return "List Partner";
  }

  // search
  get searchValue() {
    return DashboardController.searchListCourierPartnerCodDashboard;
  }
  set searchValue(newValue: string) {
    DashboardController.setSearchListCourierPartnerCodDashboard(newValue);
  }
  get searchPlaceholder() {
    if (this.accountTypeDetail === "pos") {
      return "Cari nama kurir";
    }
    return "Cari nama partner";
  }
  onSearch(value: string) {
    this.searchValue = value;
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.fetchCourierPartner();
  }
  onClear() {
    this.searchValue = "";
    DashboardController.setListCourierPartnerCodDashboard(
      new ListCourierPartnerCodDashboard()
    );
    this.fetchCourierPartner();
  }

  // table
  get firstRowHeaderColumns() {
    const temp: Array<any> = [
      {
        name: "No.",
        styleHead: "w-12 text-left whitespace-no-wrap"
      },
      {
        name: `Nama ${this.accountTypeDetail === "pos" ? "Kurir" : "Partner"}`,
        styleHead: "w-40 text-left whitespace-no-wrap"
      },
      {
        name: "Performa Pengiriman",
        colSpan: 2,
        styleHead:
          "w-350px text-center whitespace-no-wrap border-horizontal-separation"
      },
      {
        name: "Dalam<br>Pengiriman",
        styleHead: "w-32 text-center whitespace-no-wrap"
      },
      {
        name: "Pengiriman Berhasil",
        colSpan: 2,
        styleHead:
          "w-500px text-center whitespace-no-wrap border-horizontal-separation"
      },
      {
        name: "Pengiriman Gagal",
        colSpan: 4,
        styleHead:
          "w-600px text-center whitespace-no-wrap border-horizontal-separation"
      }
    ];
    if (this.accountTypeDetail === "pos") {
      temp.push({
        name: "Action",
        key: "actionable_column",
        styleHead: "w-24 text-left",
        showButton: () => true
      });
    }
    return temp;
  }

  get secondRowHeaderColumns() {
    const temp: Array<any> = [
      {
        name: "No.",
        hide: true,
        styleHead: "w-12 text-left whitespace-no-wrap"
      },
      {
        name: "Nama Kurir",
        hide: true,
        key: "actionable_column",
        styleHead: "w-40 text-left whitespace-no-wrap"
      },
      {
        name: "Sehari",
        styleHead:
          "w-40 text-left whitespace-no-wrap border-vertical-separation",
        styleRender: "border-vertical-separation",
        tooltipHeader: () => {
          return (
            "<div class='text-black-lp-300 text-12px text-left'>" +
            "<ol style='list-style-type: decimal;'>" +
            "<li>Total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari yang sama.</li>" +
            "<li>Persentasi total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari yang sama.</li>" +
            "</ol>" +
            "</div>"
          );
        }
      },
      {
        name: "Lewat Sehari",
        styleHead: "w-40 text-left whitespace-no-wrap",
        tooltipHeader: () => {
          return (
            "<div class='text-black-lp-300 text-12px text-left'>" +
            "<ol style='list-style-type: decimal;'>" +
            "<li>Total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari sebelumnya.</li>" +
            "<li>Persentasi total STT COD yang berhasil dikirim (POD) dari total keseluruhan STT COD yang dikirim oleh kurir di hari sebelumnya.</li>" +
            "</ol>" +
            "</div>"
          );
        }
      },
      {
        name: "Dalam Pengiriman",
        hide: true,
        styleHead: "w-32 text-center whitespace-no-wrap"
      },
      {
        name: "Belum Setor",
        styleHead:
          "w-40 text-left whitespace-no-wrap border-vertical-separation",
        styleRender: "border-vertical-separation",
        tooltipHeader: () => {
          return this.toolTipHeaderBelumSetor;
        }
      },
      {
        name: "Sudah Setor",
        styleHead: "w-40 text-left whitespace-no-wrap",
        tooltipHeader: () => {
          return this.toolTipHeaderSudahSetor;
        }
      },
      {
        name: "CODREJ",
        colSpan: 2,
        styleHead:
          "w-32 text-center whitespace-no-wrap border-vertical-separation",
        styleRender: "border-vertical-separation",
        tooltipHeader: () => {
          return (
            "<div class='text-black-lp-300 text-12px text-left'>" +
            "Total STT COD yang gagal dikirim (CODREJ) oleh kurir." +
            "</div>"
          );
        }
      },
      {
        name: "DEX",
        colSpan: 2,
        styleHead: "w-24 text-center whitespace-no-wrap",
        tooltipHeader: () => {
          return (
            "<div class='text-black-lp-300 text-12px text-left'>" +
            "Total STT COD yang gagal dikirim (DEX) oleh kurir." +
            "</div>"
          );
        }
      }
    ];
    if (this.accountTypeDetail === "pos") {
      temp.push({
        name: "",
        key: "actionable_column",
        styleHead: "w-24 text-left",
        showButton: () => true
      });
    }
    return temp;
  }

  get toolTipHeaderBelumSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) tetapi belum disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  get toolTipHeaderSudahSetor() {
    return (
      "<div class='text-black-lp-300 text-12px text-left'>" +
      "Total nilai COD dari STT COD yang telah berhasil dikirim (POD) dan telah disetor oleh kurir kepada admin POS." +
      "</div>"
    );
  }
  get columns() {
    const temp: Array<any> = [
      {
        name: "No.",
        hide: true,
        styleHead: "w-12 text-left whitespace-no-wrap",
        render: (_: any, index: number) => {
          return `<div class='text-left'>${index + 1}</div>`;
        }
      },
      {
        name: "Nama Kurir",
        hide: true,
        key: "actionable_column",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: CourierPartnerCodDashboard) => {
          return (
            `<div class='text-black-lp-300'>${
              this.accountTypeDetail === "pos"
                ? item.courierName
                : item.partnerName
            }</div>` +
            `<div class='text-black-lp-300'>${
              this.accountTypeDetail === "pos"
                ? item.courierPhone
                : item.partnerContactPhone
            }</div>`
          );
        },
        showButton: (item: CourierPartnerCodDashboard) => {
          if (this.accountTypeDetail === "pos") {
            return item.courierPhone;
          }
          return item.partnerContactPhone;
        },
        styleCustom: "items-end",
        icon: "whatsapp",
        disableButtonValue: () => false,
        onClickValue: this.onCLickWhatsapp
      },
      {
        name: "Sehari",
        hide: true,
        styleHead: "w-40 text-center whitespace-no-wrap",
        render: (item: CourierPartnerCodDashboard) => {
          return (
            `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttPodSameDay}<span class='font-normal'>/${item.totalStt}</span></div>` +
            `<div class='text-12px text-center text-gray-lp-800'>(${(
              (item.totalSttPodSameDay / item.totalStt) *
              100
            ).toFixed(2)}%)</div>`
          );
        }
      },
      {
        name: "Lewat Sehari",
        hide: true,
        styleHead: "w-40 text-center whitespace-no-wrap",
        render: (item: CourierPartnerCodDashboard) => {
          return (
            `<div class='text-black-lp-300 text-center font-semibold'>${item.totalSttPodDifferentDay}<span class='font-normal'>/${item.totalStt}</span></div>` +
            `<div class='text-12px text-center text-gray-lp-800'>(${(
              (item.totalSttPodDifferentDay / item.totalStt) *
              100
            ).toFixed(2)}%)</div>`
          );
        }
      },
      {
        name: "Dalam Pengiriman",
        hide: true,
        styleHead: "w-32 text-left whitespace-no-wrap",
        render: (item: CourierPartnerCodDashboard) => {
          return `${item.totalSttDel} STT`;
        }
      },
      {
        name: "(POD) Belum Setor",
        hide: true,
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: CourierPartnerCodDashboard) => {
          return `${formatPrice(item.totalOutstanding)}`;
        },
        tooltipHeader: () => {
          return this.toolTipHeaderBelumSetor;
        }
      },
      {
        name: "(POD) Sudah Setor",
        hide: true,
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: CourierPartnerCodDashboard) => {
          return `${formatPrice(item.totalCollected)}`;
        },
        tooltipHeader: () => {
          return this.toolTipHeaderSudahSetor;
        }
      },
      {
        name: "Belum Setor",
        styleHead:
          "w-40 text-left whitespace-no-wrap border-vertical-separation",
        render: (item: CourierPartnerCodDashboard) => {
          return `${item.totalSttCodrejOutstanding} STT`;
        }
      },
      {
        name: "Sudah Setor",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: CourierPartnerCodDashboard) => {
          return `${item.totalSttCodrejCollected} STT`;
        }
      },
      {
        name: "Belum Setor",
        styleHead:
          "w-40 text-left whitespace-no-wrap border-vertical-separation",
        render: (item: CourierPartnerCodDashboard) => {
          return `${item.totalSttDexOutstanding} STT`;
        }
      },
      {
        name: "Sudah Setor",
        styleHead: "w-40 text-left whitespace-no-wrap",
        render: (item: CourierPartnerCodDashboard) => {
          return `${item.totalSttDexCollected} STT`;
        }
      }
    ];
    if (this.accountTypeDetail === "pos") {
      temp.push({
        name: "",
        key: "actionable_column",
        styleHead: "w-24 text-left",
        showButton: () => true
      });
    }
    return temp;
  }
  buttonActions() {
    return [
      {
        icon: "pencil-alt-disable",
        label: "Detail Kurir",
        clickFunction: (item: any) => {
          this.$router.push(
            `/dashboard/COD/detail-courier?driver_phone=${item.courierPhone}`
          );
        }
      },
      {
        icon: "download-gray",
        label: "Download Detail Kurir",
        clickFunction: (item: any) => {
          DashboardController.getCourierDetailDownload({
            driverPhone: item.courierPhone,
            isPic: false
          });
        }
      },
      {
        icon: "setor-harian-kurir",
        label: "Setor Harian Kurir",
        clickFunction: (item: any) => {
          this.popUpSetorHarian(true, item);
        }
      }
    ];
  }

  // data table
  get dataCourierPartner() {
    return DashboardController.listCourierPartnerCodDashboard.data;
  }
  get paginationCourierPartner() {
    return DashboardController.listCourierPartnerCodDashboard.pagination;
  }
  get isLoadingCourierPartner() {
    return DashboardController.loadingListCourierPartnerCodDashboard;
  }
  get isErrorCourierPartner() {
    return DashboardController.errorListCourierPartnerCodDashboard;
  }
  fetchCourierPartner() {
    DashboardController.getListCourierPartnerCodDashboard();
  }

  // whatsapp courier / partner
  onCLickWhatsapp(item: CourierPartnerCodDashboard) {
    const phoneNumber =
      this.accountTypeDetail === "pos"
        ? item.courierPhone
        : item.partnerContactPhone;
    const messageTo =
      this.accountTypeDetail === "pos"
        ? `Kurir ${item.courierName}`
        : `Agen ${item.partnerName}`;
    const templateMessage =
      `Halo ${messageTo}\n` +
      `Kami menginformasikan ada STT COD perlu diselesaikan proses secepatnya:\n` +
      `${item.totalSttDel} STT COD masih proses pengiriman\n` +
      `${item.totalSttDexCollected +
        item.totalSttCodrejCollected} STT COD harus ${
        this.accountTypeDetail === "pos"
          ? "dikembalikan ke"
          : "dikumpulkan oleh"
      } POS\n` +
      `${formatPrice(item.totalOutstanding)} Dana harus ${
        this.accountTypeDetail === "pos" ? "disetor ke" : "dikumpulkan oleh"
      } POS\n` +
      `Harap langsung memproses pengiriman STT dan lakukan ${
        this.accountTypeDetail === "pos" ? "penyetoran" : "pengumpulan"
      } Terima kasih`;
    whatsapp(phoneNumber, templateMessage);
  }

  popupSetorHarianModelValue = false;
  detailDataCourier = {
    courierName: "",
    phoneNumber: "",
    depositMoney: 0,
    sttCodrej: 0,
    sttDex: 0
  };
  popUpSetorHarian(bool: boolean, data: any) {
    this.popupSetorHarianModelValue = bool;
    this.detailDataCourier.courierName = data.courierName;
    this.detailDataCourier.phoneNumber = data.courierPhone;
    this.detailDataCourier.depositMoney = data.totalOutstanding;
    this.detailDataCourier.sttCodrej = data.totalSttCodrejOutstanding;
    this.detailDataCourier.sttDex = data.totalSttDexOutstanding;
  }
}
