import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "pt-2" }
const _hoisted_2 = {
  class: "overflow-y-auto relative",
  style: {"height":"calc(100vh - 170px)"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Title = _resolveComponent("Title")!
  const _component_Search = _resolveComponent("Search")!
  const _component_TableV2 = _resolveComponent("TableV2")!
  const _component_ErrorMessage = _resolveComponent("ErrorMessage")!
  const _component_PopupSetorHarian = _resolveComponent("PopupSetorHarian")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_Title, {
      class: "mb-6",
      label: _ctx.titleListCourierPartner
    }, null, 8, ["label"]),
    _createVNode(_component_Search, {
      onInput: _ctx.onSearch,
      onClear: _ctx.onClear,
      value: _ctx.searchValue,
      placeholder: _ctx.searchPlaceholder,
      class: "w-72"
    }, null, 8, ["onInput", "onClear", "value", "placeholder"]),
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_TableV2, {
        columns: _ctx.columns,
        firstRowHeader: _ctx.firstRowHeaderColumns,
        secondRowHeader: _ctx.secondRowHeaderColumns,
        data: _ctx.dataCourierPartner,
        loading: _ctx.isLoadingCourierPartner,
        pagination: _ctx.paginationCourierPartner,
        "onUpdate:pagination": _cache[1] || (_cache[1] = ($event: any) => (_ctx.paginationCourierPartner = $event)),
        onRequest: _ctx.fetchCourierPartner,
        groupOptions: _ctx.buttonActions,
        groupAction: "",
        isNoPaddingBottom: "",
        pinnedSubstractHeight: "170px",
        isShowPagination: false,
        borderTop: false,
        borderBottom: false,
        infiniteScroll: "",
        iconColor: "green-lp-100",
        isRightFreeze: true,
        totalColumnFreeze: _ctx.isPos ? 1 : 0
      }, null, 8, ["columns", "firstRowHeader", "secondRowHeader", "data", "loading", "pagination", "onRequest", "groupOptions", "totalColumnFreeze"]),
      (_ctx.isErrorCourierPartner)
        ? (_openBlock(), _createBlock(_component_ErrorMessage, {
            key: 0,
            errorType: _ctx.isErrorCourierPartner,
            customClass: "h-auto mt-16"
          }, null, 8, ["errorType"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.popupSetorHarianModelValue)
      ? (_openBlock(), _createBlock(_component_PopupSetorHarian, {
          key: 0,
          modelValue: _ctx.popupSetorHarianModelValue,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.popupSetorHarianModelValue = $event)),
          detailDataCourier: _ctx.detailDataCourier
        }, null, 8, ["modelValue", "detailDataCourier"]))
      : _createCommentVNode("", true)
  ]))
}