
import { Vue, Options } from "vue-class-component";
import DetailStatusSection from "@/app/ui/views/dashboard/cod-dashboard/component/detail-status-section.vue";
import { SummaryCodDashboardData, SummaryType } from "@/domain/entities/Dashboard";
import { formatPrice } from "@/app/infrastructures/misc/Utils";
import { DashboardController } from "@/app/ui/controllers/DashboardController";

class DetailStatusSummary {
  statusName = "";
  statusSubName = "";
  toolTipDescription = "";
  sttDisplay = "";
  percentageSttDisplay = "";
  totalAmountSttDisplay = "";
  backgroundColor = "";
  iconColor = "";
  iconName = "";
  statusType = "";
  loading = false;

  constructor(fields?: Partial<DetailStatusSummary>) {
    Object.assign(this, fields);
  }
}

@Options({
  components: { DetailStatusSection }
})
export default class OverallSummarySection extends Vue {
  mounted() {
    this.getLastSyncDataDashboard();
    this.fetchSummaryCodDashboard();
  }
  listOfSummaryPerStatus: SummaryCodDashboardData[] = [
    new SummaryCodDashboardData({
      sttStatus: "TOTAL",
      loading: true
    }),
    new SummaryCodDashboardData({
      sttStatus: "DEL-collected",
      loading: true
    }),
    new SummaryCodDashboardData({
      sttStatus: "POD-collected",
      loading: true
    }),
    new SummaryCodDashboardData({
      sttStatus: "CODREJ-collected",
      loading: true
    }),
    new SummaryCodDashboardData({
      sttStatus: "DEX-collected",
      loading: true
    }),
    new SummaryCodDashboardData({
      sttStatus: "DEL-outstanding",
      loading: true
    }),
    new SummaryCodDashboardData({
      sttStatus: "POD-outstanding",
      loading: true
    }),
    new SummaryCodDashboardData({
      sttStatus: "CODREJ-outstanding",
      loading: true
    }),
    new SummaryCodDashboardData({
      sttStatus: "DEX-outstanding",
      loading: true
    })
  ];
  async fetchSummaryCodDashboard() {
    let delay = 0;
    const delayIncrement = 250;
    const requestSummaryCodDashboards = this.listOfSummaryPerStatus.map(
      (summary: SummaryCodDashboardData) => {
        delay += delayIncrement;
        return new Promise(resolve => {
          setTimeout(resolve, delay);
        }).then(() => {
          return DashboardController.getSummaryCodDashboardData(new SummaryType({
            sttStatus: summary.sttStatus,
            type: "courier"
          })).then((resp: SummaryCodDashboardData) => {
            return resp;
          });
        });
      }
    );
    this.listOfSummaryPerStatus = await Promise.all(
      requestSummaryCodDashboards
    );
  }

  async getLastSyncDataDashboard() {
    const response = await DashboardController.getLastSyncCOD();
    const lastSyncCODDashboard = !response.lastSyncDate
      ? ""
      : this.$moment(response.lastSyncDate).format("DD MMMM YYYY, HH:mm");
    DashboardController.setLastSycnCODDashboard(lastSyncCODDashboard);
  }

  calculatePercentage(xNumber: number, yNumber: number): string {
    if (xNumber === 0 || yNumber === 0) return "0";
    return ((xNumber / yNumber) * 100).toFixed(2);
  }

  get totalDataCodDashboard() {
    const summaryData = this.listOfSummaryPerStatus.find(
      (summary: SummaryCodDashboardData) => summary.sttStatus === "TOTAL"
    );
    return summaryData ? summaryData : new SummaryCodDashboardData();
  }
  get dataDelCollectedCodDashboard() {
    const summaryData = this.listOfSummaryPerStatus.find(
      (summary: SummaryCodDashboardData) =>
        summary.sttStatus === "DEL-collected"
    );
    return summaryData ? summaryData : new SummaryCodDashboardData();
  }
  get dataPodCollectedCodDashboard() {
    const summaryData = this.listOfSummaryPerStatus.find(
      (summary: SummaryCodDashboardData) =>
        summary.sttStatus === "POD-collected"
    );
    return summaryData ? summaryData : new SummaryCodDashboardData();
  }
  get dataDexCollectedCodDashboard() {
    const summaryData = this.listOfSummaryPerStatus.find(
      (summary: SummaryCodDashboardData) =>
        summary.sttStatus === "DEX-collected"
    );
    return summaryData ? summaryData : new SummaryCodDashboardData();
  }
  get dataCodRejCollectedCodDashboard() {
    const summaryData = this.listOfSummaryPerStatus.find(
      (summary: SummaryCodDashboardData) =>
        summary.sttStatus === "CODREJ-collected"
    );
    return summaryData ? summaryData : new SummaryCodDashboardData();
  }
  get dataDelOutstandingCodDashboard() {
    const summaryData = this.listOfSummaryPerStatus.find(
      (summary: SummaryCodDashboardData) =>
        summary.sttStatus === "DEL-outstanding"
    );
    return summaryData ? summaryData : new SummaryCodDashboardData();
  }
  get dataPodOutstandingCodDashboard() {
    const summaryData = this.listOfSummaryPerStatus.find(
      (summary: SummaryCodDashboardData) =>
        summary.sttStatus === "POD-outstanding"
    );
    return summaryData ? summaryData : new SummaryCodDashboardData();
  }
  get dataDexOutstandingCodDashboard() {
    const summaryData = this.listOfSummaryPerStatus.find(
      (summary: SummaryCodDashboardData) =>
        summary.sttStatus === "DEX-outstanding"
    );
    return summaryData ? summaryData : new SummaryCodDashboardData();
  }
  get dataCodRejOutstandingCodDashboard() {
    const summaryData = this.listOfSummaryPerStatus.find(
      (summary: SummaryCodDashboardData) =>
        summary.sttStatus === "CODREJ-outstanding"
    );
    return summaryData ? summaryData : new SummaryCodDashboardData();
  }

  get statusDelSection() {
    return new DetailStatusSummary({
      loading:
        this.dataDelCollectedCodDashboard.loading ||
        this.dataDelOutstandingCodDashboard.loading ||
        this.totalDataCodDashboard.loading,
      statusType: "DEL",
      iconName: "delivery",
      backgroundColor: "yellow-lp-400",
      iconColor: "yellow-lp-500",
      statusName: "Dalam Proses Pengiriman",
      sttDisplay: this.dataDelCollectedCodDashboard.sttQuantity.toString(),
      totalAmountSttDisplay: formatPrice(
        this.dataDelCollectedCodDashboard.sttTotalCodAmount
      ),
      percentageSttDisplay: this.calculatePercentage(
        this.dataDelCollectedCodDashboard.sttQuantity,
        this.totalDataCodDashboard.sttQuantity
      ),
      toolTipDescription:
        "<ol style='list-style-type: decimal;'>" +
        "<li>Total STT COD yang sedang dalam pengiriman (DEL).</li>" +
        "<li><span class='text-red-lp-300'>Persentase STT COD yang sedang dalam pengiriman (DEL) dari total keseluruhan STT COD.</span></li>" +
        "<li><span class='text-green-lp-400'>Total nilai COD dari STT COD yang sedang dalam pengiriman.</span></li>" +
        "</ol>"
    });
  }

  get statusTotalPodSection() {
    return new DetailStatusSummary({
      loading:
        this.dataPodCollectedCodDashboard.loading ||
        this.totalDataCodDashboard.loading,
      iconName: "pod-dex",
      backgroundColor: "green-lp-500",
      iconColor: "green-lp-400",
      statusName: "Pengiriman Berhasil",
      sttDisplay: (
        this.dataPodCollectedCodDashboard.sttQuantity +
        this.dataPodOutstandingCodDashboard.sttQuantity
      ).toString(),
      totalAmountSttDisplay: formatPrice(
        this.dataPodCollectedCodDashboard.sttTotalCodAmount +
          this.dataPodOutstandingCodDashboard.sttTotalCodAmount
      ),
      percentageSttDisplay: this.calculatePercentage(
        this.dataPodCollectedCodDashboard.sttQuantity +
          this.dataPodOutstandingCodDashboard.sttQuantity,
        this.totalDataCodDashboard.sttQuantity
      ),
      toolTipDescription:
        "<ol style='list-style-type: decimal;'>" +
        "<li><span class='text-green-lp-400'>Total nilai COD dari STT COD yang berhasil dikirim (POD) kepada penerima.</span></li>" +
        "<li>Total STT COD yang berhasil dikirim (POD).</li>" +
        "<li><span class='text-red-lp-300'>Persentase keberhasilan pengiriman STT COD (POD) dari total keseluruhan STT COD.</span></li>" +
        "</ol>"
    });
  }
  get statusOutstandingPodSection() {
    return new DetailStatusSummary({
      loading:
        this.dataPodOutstandingCodDashboard.loading ||
        this.totalDataCodDashboard.loading,
      statusType: "POD",
      iconName: "pod-dex",
      backgroundColor: "green-lp-500",
      iconColor: "green-lp-400",
      statusName: "Pengiriman Berhasil",
      statusSubName: "(Belum Setor)",
      sttDisplay: `${this.dataPodOutstandingCodDashboard.sttQuantity}/${this
        .dataPodCollectedCodDashboard.sttQuantity +
        this.dataPodOutstandingCodDashboard.sttQuantity}`,
      totalAmountSttDisplay: formatPrice(
        this.dataPodOutstandingCodDashboard.sttTotalCodAmount
      ),
      percentageSttDisplay: this.calculatePercentage(
        this.dataPodOutstandingCodDashboard.sttQuantity,
        this.dataPodCollectedCodDashboard.sttQuantity +
          this.dataPodOutstandingCodDashboard.sttQuantity
      ),
      toolTipDescription:
        "<ol style='list-style-type: decimal;'>" +
        "<li><span class='text-green-lp-400'>Total nilai COD yang berhasil dikirim (POD) kepada penerima dan uang COD belum disetor oleh kurir ke admin POS.</span></li>" +
        "<li>Total STT COD yang berhasil dikirim dan uang COD belum disetor oleh kurir ke admin POS/Total keseluruhan STT COD yang berhasil dikirim (sudah setor dan belum setor).</li>" +
        "<li><span class='text-red-lp-300'>Persentase total STT COD yang berhasil terkirim dan uang COD belum disetor oleh kurir ke admin POS dari total keseluruhan nilai COD yang berhasil dikirim (sudah setor dan belum setor).</span></li>" +
        "</ol>"
    });
  }
  get statusCollectedPodSection() {
    return new DetailStatusSummary({
      loading:
        this.dataPodCollectedCodDashboard.loading ||
        this.totalDataCodDashboard.loading,
      iconName: "pod-dex",
      backgroundColor: "green-lp-500",
      iconColor: "green-lp-400",
      statusName: "Pengiriman Berhasil",
      statusSubName: "(Sudah Setor)",
      sttDisplay: `${this.dataPodCollectedCodDashboard.sttQuantity}/${this
        .dataPodCollectedCodDashboard.sttQuantity +
        this.dataPodOutstandingCodDashboard.sttQuantity}`,
      totalAmountSttDisplay: formatPrice(
        this.dataPodCollectedCodDashboard.sttTotalCodAmount
      ),
      percentageSttDisplay: this.calculatePercentage(
        this.dataPodCollectedCodDashboard.sttQuantity,
        this.dataPodCollectedCodDashboard.sttQuantity +
          this.dataPodOutstandingCodDashboard.sttQuantity
      ),
      toolTipDescription:
        "<ol style='list-style-type: decimal;'>" +
        "<li><span class='text-green-lp-400'>Total nilai COD yang berhasil dikirim (POD) kepada penerima dan uang COD sudah disetor oleh kurir ke admin POS.</span></li>" +
        "<li>Total STT COD yang berhasil dikirim dan uang COD sudah disetor oleh kurir ke admin POS/Total keseluruhan STT COD yang berhasil dikirim (sudah setor dan belum setor).</li>" +
        "<li><span class='text-red-lp-300'>Persentase total STT COD yang berhasil terkirim dan uang COD sudah disetor oleh kurir ke admin POS dari total keseluruhan nilai COD yang berhasil dikirim (sudah setor dan belum setor).</span></li>" +
        "</ol>"
    });
  }

  get statusTotalFailedSection() {
    return new DetailStatusSummary({
      loading:
        this.totalDataCodDashboard.loading ||
        this.dataDexOutstandingCodDashboard.loading ||
        this.dataDexCollectedCodDashboard.loading ||
        this.dataCodRejOutstandingCodDashboard.loading ||
        this.dataCodRejCollectedCodDashboard.loading,
      iconName: "codrej-dex",
      backgroundColor: "red-lp-1100",
      iconColor: "red-lp-300",
      statusName: "Pengiriman Gagal",
      sttDisplay: (
        this.dataDexOutstandingCodDashboard.sttQuantity +
        this.dataDexCollectedCodDashboard.sttQuantity +
        this.dataCodRejOutstandingCodDashboard.sttQuantity +
        this.dataCodRejCollectedCodDashboard.sttQuantity
      ).toString(),
      percentageSttDisplay: this.calculatePercentage(
        this.dataDexOutstandingCodDashboard.sttQuantity +
          this.dataDexCollectedCodDashboard.sttQuantity +
          this.dataCodRejOutstandingCodDashboard.sttQuantity +
          this.dataCodRejCollectedCodDashboard.sttQuantity,
        this.totalDataCodDashboard.sttQuantity
      ),
      toolTipDescription:
        "<ol style='list-style-type: decimal;'>" +
        "<li>STT COD yang gagal dikirim (DEX & CODREJ) kepada penerima.</li>" +
        "<li><span class='text-red-lp-300'>Persentase kegagalan pengiriman STT COD (DEX & CODREJ) dari total keseluruhan STT COD.</span></li>" +
        "</ol>"
    });
  }
  get statusFailedCodrejOutstandingSection() {
    return new DetailStatusSummary({
      loading:
        this.totalDataCodDashboard.loading ||
        this.dataCodRejOutstandingCodDashboard.loading,
      statusType: "CODREJ",
      iconName: "codrej-dex",
      backgroundColor: "red-lp-1100",
      iconColor: "red-lp-300",
      statusName: "Pengiriman Gagal (CODREJ)",
      statusSubName: "(Belum Setor)",
      sttDisplay: `${this.dataCodRejOutstandingCodDashboard.sttQuantity}/${this
        .dataCodRejOutstandingCodDashboard.sttQuantity +
        this.dataCodRejCollectedCodDashboard.sttQuantity +
        this.dataDexOutstandingCodDashboard.sttQuantity +
        this.dataDexCollectedCodDashboard.sttQuantity}`,
      totalAmountSttDisplay: "",
      percentageSttDisplay: this.calculatePercentage(
        this.dataCodRejOutstandingCodDashboard.sttQuantity,
        this.dataCodRejOutstandingCodDashboard.sttQuantity +
          this.dataCodRejCollectedCodDashboard.sttQuantity +
          this.dataDexOutstandingCodDashboard.sttQuantity +
          this.dataDexCollectedCodDashboard.sttQuantity
      ),
      toolTipDescription:
        "<ol style='list-style-type: decimal;'>" +
        "<li><span class='text-green-lp-400'>Total nilai COD yang gagal dikirim (CODREJ) kepada penerima dan uang COD belum disetor oleh kurir ke admin POS.</span></li>" +
        "<li>Total STT COD yang gagal dikirim dan uang COD belum disetor oleh kurir ke admin POS/Total keseluruhan STT COD yang berhasil dikirim (sudah setor dan belum setor).</li>" +
        "<li><span class='text-red-lp-300'>Persentase total STT COD yang gagal terkirim dan uang COD belum disetor oleh kurir ke admin POS dari total keseluruhan nilai COD yang berhasil dikirim (sudah setor dan belum setor).</span></li>" +
        "</ol>"
    });
  }

  get statusCollectedCodrejSection() {
    return new DetailStatusSummary({
      loading:
        this.totalDataCodDashboard.loading ||
        this.dataCodRejCollectedCodDashboard.loading,
      iconName: "codrej-dex",
      backgroundColor: "red-lp-1100",
      iconColor: "red-lp-300",
      statusName: "Pengiriman Gagal (CODREJ)",
      statusSubName: "(Sudah Setor)",
      sttDisplay: `${this.dataCodRejCollectedCodDashboard.sttQuantity}/${this
        .dataCodRejCollectedCodDashboard.sttQuantity +
        this.dataCodRejOutstandingCodDashboard.sttQuantity +
        this.dataDexCollectedCodDashboard.sttQuantity +
        this.dataDexOutstandingCodDashboard.sttQuantity}`,
      percentageSttDisplay: this.calculatePercentage(
        this.dataCodRejCollectedCodDashboard.sttQuantity,
        this.dataCodRejCollectedCodDashboard.sttQuantity +
          this.dataCodRejOutstandingCodDashboard.sttQuantity +
          this.dataDexCollectedCodDashboard.sttQuantity +
          this.dataDexOutstandingCodDashboard.sttQuantity
      ),
      toolTipDescription:
        "<ol style='list-style-type: decimal;'>" +
        "<li><span class='text-green-lp-400'>Total nilai COD yang gagal dikirim (CODREJ) kepada penerima dan uang COD sudah disetor oleh kurir ke admin POS.</span></li>" +
        "<li>Total STT COD yang gagal dikirim dan uang COD sudah disetor oleh kurir ke admin POS/Total keseluruhan STT COD yang berhasil dikirim (sudah setor dan belum setor).</li>" +
        "<li><span class='text-red-lp-300'>Persentase total STT COD yang gagal terkirim dan uang COD sudah disetor oleh kurir ke admin POS dari total keseluruhan nilai COD yang berhasil dikirim (sudah setor dan belum setor).</span></li>" +
        "</ol>"
    });
  }

  get statusFailedDexOustandingSection() {
    return new DetailStatusSummary({
      loading:
        this.totalDataCodDashboard.loading ||
        this.dataDexOutstandingCodDashboard.loading,
      statusType: "DEX",
      iconName: "codrej-dex",
      backgroundColor: "red-lp-1100",
      iconColor: "red-lp-300",
      statusName: "Pengiriman Gagal (DEX)",
      statusSubName: "(Belum Setor)",
      sttDisplay: `${this.dataDexOutstandingCodDashboard.sttQuantity}/${this
        .dataCodRejOutstandingCodDashboard.sttQuantity +
        this.dataCodRejCollectedCodDashboard.sttQuantity +
        this.dataDexOutstandingCodDashboard.sttQuantity +
        this.dataDexCollectedCodDashboard.sttQuantity}`,
      percentageSttDisplay: this.calculatePercentage(
        this.dataDexOutstandingCodDashboard.sttQuantity,
        this.dataCodRejOutstandingCodDashboard.sttQuantity +
          this.dataCodRejCollectedCodDashboard.sttQuantity +
          this.dataDexOutstandingCodDashboard.sttQuantity +
          this.dataDexCollectedCodDashboard.sttQuantity
      ),
      toolTipDescription:
        "<ol style='list-style-type: decimal;'>" +
        "<li><span class='text-green-lp-400'>Total nilai COD yang gagal dikirim (DEX) kepada penerima dan uang COD belum disetor oleh kurir ke admin POS.</span></li>" +
        "<li>Total STT COD yang gagal dikirim dan uang COD belum disetor oleh kurir ke admin POS/Total keseluruhan STT COD yang berhasil dikirim (sudah setor dan belum setor).</li>" +
        "<li><span class='text-red-lp-300'>Persentase total STT COD yang gagal terkirim dan uang COD belum disetor oleh kurir ke admin POS dari total keseluruhan nilai COD yang berhasil dikirim (sudah setor dan belum setor).</span></li>" +
        "</ol>"
    });
  }

  get statusCollectedDexSection() {
    return new DetailStatusSummary({
      loading:
        this.totalDataCodDashboard.loading ||
        this.dataDexCollectedCodDashboard.loading,
      iconName: "codrej-dex",
      backgroundColor: "red-lp-1100",
      iconColor: "red-lp-300",
      statusName: "Pengiriman Gagal (DEX)",
      statusSubName: "(Sudah Setor)",
      sttDisplay: `${this.dataDexCollectedCodDashboard.sttQuantity}/${this
        .dataCodRejCollectedCodDashboard.sttQuantity +
        this.dataCodRejOutstandingCodDashboard.sttQuantity +
        this.dataDexCollectedCodDashboard.sttQuantity +
        this.dataDexOutstandingCodDashboard.sttQuantity}`,
      percentageSttDisplay: this.calculatePercentage(
        this.dataDexCollectedCodDashboard.sttQuantity,
        this.dataCodRejCollectedCodDashboard.sttQuantity +
          this.dataCodRejOutstandingCodDashboard.sttQuantity +
          this.dataDexCollectedCodDashboard.sttQuantity +
          this.dataDexOutstandingCodDashboard.sttQuantity
      ),
      toolTipDescription:
        "<ol style='list-style-type: decimal;'>" +
        "<li><span class='text-green-lp-400'>Total nilai COD yang gagal dikirim (DEX) kepada penerima dan uang COD sudah disetor oleh kurir ke admin POS.</span></li>" +
        "<li>Total STT COD yang gagal dikirim dan uang COD sudah disetor oleh kurir ke admin POS/Total keseluruhan STT COD yang berhasil dikirim (sudah setor dan belum setor).</li>" +
        "<li><span class='text-red-lp-300'>Persentase total STT COD yang gagal terkirim dan uang COD sudah disetor oleh kurir ke admin POS dari total keseluruhan nilai COD yang berhasil dikirim (sudah setor dan belum setor).</span></li>" +
        "</ol>"
    });
  }
}
